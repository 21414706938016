import React, { useMemo, useRef, useState } from "react";

import MaterialHidden from "@material-ui/core/Hidden";
import { useTranslation } from "next-i18next";
import { useIntersection } from "react-use";

import { Column, Hidden, Row } from "@/components/grid";
import { Snackbar } from "@/components/snackbar";
import { Typography } from "@/components/typography/typography";
import { FullScreenDialog } from "@/design-system/organisms/dialog/fullscreen";
import { useFooterIntersectionContext } from "@/hooks/intersection";
import { useScrollToWithHeader } from "@/hooks/scroll";
import { FONT_WEIGHT } from "@/theme";

import type { JobApplicationFormWithSnackbarProps } from "../";
import { ApplicationFormType, StyledCheckIcon, StyledFeature, StyledFeatures } from "../";

import { JobApplicationForm } from ".";

export const JobApplicationFormWithSnackbar = ({
	isAgentApplicationForm,
	jobLocations,
	textCollection,
}: JobApplicationFormWithSnackbarProps) => {
	const { t } = useTranslation(["common", "forms"]);
	const [isOpen, setIsOpen] = useState(false);

	const ref = useRef<HTMLDivElement>(null);
	const formIntersecting = useIntersection(ref, {
		root: null,
		rootMargin: "0px",
		threshold: 0,
	});
	const { intersecting: footerIntersecting } = useFooterIntersectionContext();
	const showSnackbar = useMemo(
		() => !formIntersecting?.isIntersecting && !footerIntersecting,
		[formIntersecting, footerIntersecting]
	);
	const scrollTo = useScrollToWithHeader("contact-form");

	return (
		<>
			<Hidden l>
				<JobApplicationForm
					formType={ApplicationFormType.QUICK}
					isAgentApplicationForm={isAgentApplicationForm}
					forwardedRef={ref}
					jobLocations={jobLocations}
					textCollection={textCollection}
				/>
			</Hidden>
			<MaterialHidden lgUp>
				<FullScreenDialog
					ariaLabeledById="apply-dialog-aria-label"
					open={isOpen}
					onClose={() => {
						setIsOpen(false);
					}}
				>
					<JobApplicationForm
						formType={ApplicationFormType.QUICK}
						isAgentApplicationForm={isAgentApplicationForm}
						jobLocations={jobLocations}
						textCollection={textCollection}
					/>
				</FullScreenDialog>
			</MaterialHidden>

			<Hidden s m>
				<Snackbar
					buttonLabel={t("forms:apply-now:button-label")}
					handleClick={() => {
						setIsOpen(true);
					}}
					showSnackbar={showSnackbar}
				/>
			</Hidden>
			<Hidden l>
				<Snackbar
					arrowIcon
					buttonLabel={t("forms:apply-now:button-label")}
					handleClick={scrollTo}
					showSnackbar={showSnackbar}
				>
					<Row>
						<Column flex l={6}>
							<StyledFeatures>
								<StyledFeature>
									<StyledCheckIcon fontSize="large" />
									<Typography tight component="h5" weight={FONT_WEIGHT.medium}>
										{t("common:personal")}
									</Typography>
								</StyledFeature>
								<StyledFeature>
									<StyledCheckIcon fontSize="large" />
									<Typography tight component="h5" weight={FONT_WEIGHT.medium}>
										{t("common:transparent")}
									</Typography>
								</StyledFeature>
								<StyledFeature>
									<StyledCheckIcon fontSize="large" />
									<Typography tight component="h5" weight={FONT_WEIGHT.medium}>
										{t("common:authentic")}
									</Typography>
								</StyledFeature>
							</StyledFeatures>
						</Column>
					</Row>
				</Snackbar>
			</Hidden>
		</>
	);
};
